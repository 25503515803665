import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link } from 'gatsby'
// import { css } from '@emotion/react'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import classNames from 'classnames'
import { globalHistory as history } from '@reach/router'


// Theme
import theme from '../utils/theme';


// Context
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/globalContextProvider'


// Components
import Icon from './icon'
import Headroom from '../utils/headroom'
import ScrollProgress from './scrollProgress'
import NewsTicker from './newsTicker'
//import NewsProgress from './newsProgress'

import OverflowCheck from '../utils/overflowCheck'
import SelectedWorksLink from '../utils/selectedWorksLink'
import {PillStyle} from '../utils/styles'
import { useSiteContext } from '../utils/SiteProvider'


const MenuToggle = styled('button')`
  appearance: none;
  background: transparent;
  padding: 0;
  width: 34px;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  position: fixed;
  top: 10px;
  left: 15px;

  display: flex;
  align-items: center;
  height: 34px;
  border-radius: 27px;
  text-transform: uppercase;
  box-sizing: border-box;
  outline: none;
  border: 2px solid #000000;
  transition: opacity 0.25s ease-in-out;

  opacity: ${props =>
    props.introFinished ? '1' : '0' };


  ${props => {
    if (props.toggleMenu) {
      return `
        border-color: #fff;
    `
    } else if (props.theme == 'orange') {
      return `
        border-color: #fff;
    `
    } else {
      return `
        border-color: #000;
    `
    }
  }}

  svg {
    width: 13.5px;
    transition: all 0.1s ease-in-out;

    ${props => {
      if (props.toggleMenu) {
        return `
          stroke: #fff;
      `
      } else if (props.theme == 'orange') {
        return `
          stroke: #fff;
      `
      } else {
        return `
          stroke: #000;
      `
      }
    }}

    @media ${theme.breakpoints.md} { 
      width: 20px;
    }
  }

  @media (hover:hover) {
    &:hover {
      ${props => {
        if (props.toggleMenu) {
          return `
            background-color: #fff;
        `
        } else if (props.theme == 'orange') {
          return `
            background-color: #fff;
        `
        } else {
          return `
            background-color: #000;
        `
        }
      }}

      svg {
        ${props => {
          if (props.theme == 'orange' && props.toggleMenu) {
            return `
              stroke: #000000;
          `
          } else if (props.theme == 'white' && props.toggleMenu) {
            return `
              stroke: #000000;
          `
          } else if (props.theme == 'orange') {
            return `
              stroke: #E45A5A;
            `
          } else {
            return `
              stroke: #fff;
          `
          }
        }}

      }
    }
  }

  @media ${theme.breakpoints.md} { 
    left: 20px;
    width: 40px;
    height: 40px;
  }
`

/*
const StickyHead = styled(ReactStickyHeadroom)`
  // Needed so that close button is accessible on Field Notes
  pointer-events: none;
`
*/

const Navigation = styled('div')`
  height: 90px;
  padding: 10px 0 0;
  margin-right: 15px;
  margin-left: 49px;

  transition: opacity 0.25s ease-in-out;
  opacity: ${props =>
    props.introFinished ? '1' : '0' };

  @media ${theme.breakpoints.md} { 
    height: 100px;
    margin-left: 60px;
    margin-right: 20px;
  }
`

const MainNav = styled('div')`
  display: flex;
  pointer-events: auto;
`

/*
const PillStyle = 
  css`
  display: inline-block;
  box-sizing: border-box;
  margin-left: 8px;
  padding: 9px 10px;

  color: inherit;

  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.04em;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  border: 2px solid #000;
  border-radius: 27px;
  
  user-select: none;

  transition: color 0.1s ease-in-out, background-color: 0.1s ease-in-out;
  
  @media (hover:hover) {
    &:hover {
      background-color: #000000;
      color: #ffffff;
      cursor: pointer;
    }
  }

  @media ${theme.breakpoints.md} { 
    margin-left: 10px;
    padding: 10px 18px;

    font-size: 16px;
    letter-spacing: 0.02em;
  }
  `
*/

const WorksLink = styled(SelectedWorksLink)`
  ${PillStyle}

  ${props => {
    if (props.theme == 'orange') {
      return `
        border-color: ${theme.colors.white};
        color: ${theme.colors.white};
      `
    } else {
      return `
        border-color: ${theme.colors.black};
        color: ${theme.colors.black};
      `
    }
  }}

  // Variations
  &.active {
    background-color: #000;
    color: #fff;
  }

  &.ticker {
    padding-left: 0;
    padding-right: 0;

    &.active {
      padding: 0;
    }
  }

  &.orange {
    color: ${theme.colors.orange};
    border-color: ${theme.colors.orange};

    &.active {
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.white};
      color: ${theme.colors.orange};
    }
  }

  // Children
  .mobile {
    display: block;
    padding: 0 10px;
  }

  .desktop {
    display: none;
    width: 100%;
  }

  // States
  @media (hover:hover) {
    &:hover {
      &.orange {
        background-color: ${theme.colors.orange};
        color: ${theme.colors.white};
      }

      &.orange.active {
        background-color: transparent;
        color: ${theme.colors.orange};
      }

      ${props => {
        if (props.theme == 'orange') {
          return `
            background-color: ${theme.colors.white};
            color: ${theme.colors.orange};
          `
        } else {
          return `
            background-color: ${theme.colors.black};
            color: ${theme.colors.white};
          `
        }
      }}
    }
  }

  // Media Queries
  @media ${theme.breakpoints.md} { 
    &.fill {
      position: relative;
      flex: 1;
    }

    &.orange.active {
      background-color: transparent;
      color: ${theme.colors.white};
      border-color: ${theme.colors.white};
    }

    .mobile {
      display: none;
    }

    .desktop {
      position: relative;
      width: 100%;
      height: 100%;

      display: block;
    }
  }
`

const PageLink = styled(Link, { shouldForwardProp: prop => prop !== 'toggleMenu' })`
  ${PillStyle}

  ${props => {
    if (props.theme == 'orange') {
      return `
        border-color: ${theme.colors.white};
        color: ${theme.colors.white};
      `
    } else {
      return `
        border-color: ${theme.colors.black};
        color: ${theme.colors.black};
      `
    }
  }}

  // Variations
  &.active {
    background-color: #000;
    color: #fff;
  }

  &.ticker {
    //padding-left: 0;
    //padding-right: 0;

    /*
    &.active {
      padding: 0;
    }

    &.active .mobile {
      padding: 9px 10px;
    }
    */

    &.active .desktop {
      padding: 0;
    }
  }

  &.orange {
    color: ${theme.colors.orange};
    border-color: ${theme.colors.orange};

    &.active {
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.white};
      color: ${theme.colors.orange};
    }
  }

  // Children
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
    width: 100%;
  }

  // States
  @media (hover:hover) {
    &:hover {
      &.orange {
        background-color: ${theme.colors.orange};
        color: ${theme.colors.white};
      }

      &.orange.active {
        background-color: transparent;
        color: ${theme.colors.orange};
      }

      ${props => {
        if (props.theme == 'orange') {
          return `
            background-color: ${theme.colors.white};
            color: ${theme.colors.orange};
          `
        } else {
          return `
            background-color: ${theme.colors.black};
            color: ${theme.colors.white};
          `
        }
      }}
    }
  }

  // Media Queries
  @media ${theme.breakpoints.md} { 
    &.fill {
      position: relative;
      flex: 1;
    }

    &.orange.active {
      background-color: transparent;
      color: ${theme.colors.white};
      border-color: ${theme.colors.white};
    }

    &.ticker {
      padding: 0;
    }

    .mobile {
      display: none;
    }

    .desktop {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 11px 0;

      display: block;
    }
  }
`

const Status = styled('div')`
  display: flex;
  // grid-template-columns: auto min-content;

  margin-top: 10px;
  margin-right: ${props =>
    props.modalIsOpen ? '42px' : '0' };
  opacity: ${props =>
    props.hideStatusContainer ? '0' : '1' };
  pointer-events: ${props =>
    props.hideStatusContainer ? 'none' : 'auto' };
  transition: transform 0.15s ease-in-out, opacity 0.2s ease-in-out, margin 0.5s ease-in-out;

  @media ${theme.breakpoints.lg} { 
    margin-top: 10px;
    margin-right: ${props =>
      props.modalIsOpen ? '50px' : '0' };
  }
`

const PageIndicator = styled('div')`
  ${PillStyle}
  white-space: normal;
  //overflow: hidden;

  &.project {
    //flex: 1;
    overflow: hidden;
    white-space: pre;
    padding: 10px 0;
    //border: none;
  }

  &.active {
    background-color: #000;
    color: #fff;
  }

  span {
    white-space: nowrap;
  }

  @media ${theme.breakpoints.md} { 
    &.project {
      padding: 11px 0;
    }
  }
`


function viewportIsBelowMd() {
  const value = (window.innerWidth < 768) ? true : false // 768 == theme.breakpoints.md

  return value
}


const Header = ({
    toggleMenu, 
    setToggleMenu, 
    disableHeadroom, // temp
    currentProject, // for index page
    fieldNotes, // for index page
    handleOpenModal, // for index page
    modalIsOpen, // for index page
    hideStatusContainer, // for index page
    newsTitles, // for news page
    isCurrent, // for about page
    handleMenuClick,
    isShortcut,
    // siteRef,
    // handleInPageNavigation, // status bar
    toggleShortcut,
    setHideStatusContainer,
    introFinished
  }) => {

  const fieldNotesButton = useRef(null)
  // console.log(siteRef)

  const siteRef = useSiteContext()

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext) || { theme: 'white', headroom: null }

  const { location, navigate } = history
  const pathname = location.pathname // **will change with every location update**


  const handleInPageNavigation = (anchor) => {
    toggleShortcut(true)

    setTimeout(() => {
      const target = document.getElementById(anchor)
      const container = siteRef?.current
      container.scrollTop = target.offsetTop

      setTimeout(() => {
        toggleShortcut(false)
        setHideStatusContainer(false)
      }, 50)
    }, 100)
  }

  const [scrollHeight, setScrollHeight] = useState(null)
  const [newsComponent, setNewsComponent] = useState(null)

  // If we're on News Page, whole menu should stick, otherwise just show half menu.
  /*
  useEffect(() => {
    if(pathname === '/news') {
      setScrollHeight(0)
    } else {
      if(viewportIsBelowMd()) {
        setScrollHeight(45)
      } else {
        setScrollHeight(50)
      }
    }
  }, [pathname]);
  */

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // console.log('resize')

      // const match = pathname.match(/de-->([^<]+).+?en[^>]+>([^<]+)/i)
      // console.log(match)
      // console.log(/^(\/news\/(.*))$/.test(pathname)) // false

      if(pathname === '/news' || (/^(\/news\/(.*))$/.test(pathname))) {
        setScrollHeight(0)
      } else {
        if(viewportIsBelowMd()) {
          setScrollHeight(44)
        } else {
          setScrollHeight(50)
        }
      }
    }
    
    // Add event listener
    window.addEventListener('resize', handleResize)
    
    // Call handler right away so state gets updated with initial window size
    handleResize()
    
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [pathname]) // Empty array ensures that effect is only run on mount






  const handleMenu = () => {
    setToggleMenu(!toggleMenu)
  }

  /*
  const handleChange = (isOverflowed) => {
    console.log(isOverflowed)
  }

  function handleOverflowChange(isOverflowed) {
    console.log(isOverflowed);
  }
  */

  //console.log(pathname)
  //console.log((/^(\/news\/(.*))$/.test(pathname)))

  //let newsComponent
  useEffect(() => {
    if(pathname === '/news' || (/^(\/news\/(.*))$/.test(pathname))) {
      setNewsComponent(<ScrollProgress newsTitles={newsTitles} siteRef={siteRef} />)
    } else {
      setNewsComponent(<NewsTicker />)
    }
  }, [pathname, newsTitles])


  return (
    <>
      <MenuToggle
        theme={state.theme} 
        toggleMenu={toggleMenu} 
        onClick={handleMenu}
        introFinished={introFinished}
      >
        { toggleMenu
          ? <Icon symbol='cross' />
          : <Icon symbol='hamburger' />
        }
      </MenuToggle>

      
      <Headroom 
        pinStart={0} // 0 desktop
        scrollHeight={scrollHeight} // 45 mobile, 50 desktop, 0 for news
        modalIsOpen={modalIsOpen}
        toggleMenu={toggleMenu}
        //fieldNotesButton={fieldNotesButton}
        isShortcut={isShortcut}
        // siteRef={siteRef}
        introFinished={introFinished}
        //newRef={newRef}
      >
        <Navigation introFinished={introFinished}>
          <MainNav>
            <WorksLink theme={state.theme} to='/'>Selected Works</WorksLink>
            {/*<PageLink theme={state.theme} activeClassName='active' to='/'>Selected Works</PageLink>*/}
            <PageLink theme={state.theme} activeClassName='active' partiallyActive={true} to='/about'>About</PageLink>
            <PageLink theme={state.theme} activeClassName='active' partiallyActive={true} className='fill ticker orange' to='/news'>
              <div className='mobile'>News</div>
              <div className='desktop'>
                { newsComponent }
              </div>
            </PageLink>
          </MainNav>
          <Status 
            hideStatusContainer={hideStatusContainer}
            modalIsOpen={modalIsOpen}
          >
            { pathname !== '/about' &&
              <>
                { currentProject &&
                  <PageIndicator
                    className={classNames(
                      { active: !modalIsOpen },
                      'project'
                    )}
                  >
                    <OverflowCheck modalIsOpen={modalIsOpen}>
                      {currentProject}
                    </OverflowCheck>
                  </PageIndicator>
                }
                {/*{ fieldNotes &&
                  <PageIndicator 
                    onClick={handleOpenModal}
                    ref={fieldNotesButton}
                    className={classNames({
                      active: modalIsOpen,
                    })}
                  >
                    Field Notes
                  </PageIndicator>
                }*/}
                { fieldNotes &&
                  <PageIndicator 
                    onClick={handleOpenModal}
                    ref={fieldNotesButton}
                    className={classNames({
                      active: modalIsOpen,
                    })}
                  >
                    <span>Field Notes</span>
                  </PageIndicator>
                }
              </>
            }

            {
              (pathname === '/about' || pathname === '/about/' || pathname === '/about/biography' || pathname === '/about/contact') &&
                <>
                  <PageIndicator 
                    onClick={ () => handleInPageNavigation('biography') } 
                    //href='biography'
                    className={classNames({
                      active: isCurrent === 'Biography',
                    })}
                  >
                    Biography
                  </PageIndicator>
                  <PageIndicator 
                    onClick={ () => handleInPageNavigation('contact') }
                    //href='contact'
                    className={classNames({
                      active: isCurrent === 'Contact',
                    })}
                  >
                    Contact
                  </PageIndicator>
                </>
            }
          </Status>
        </Navigation>
      </Headroom>      
    </>
  )
}

export default Header